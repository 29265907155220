export const removeVietnameseTones = (str) => {
  const accents = [
    "á",
    "à",
    "ả",
    "ã",
    "ạ",
    "ă",
    "ắ",
    "ằ",
    "ẳ",
    "ẵ",
    "ặ",
    "â",
    "ấ",
    "ầ",
    "ẩ",
    "ẫ",
    "ậ",
    "đ",
    "é",
    "è",
    "ẻ",
    "ẽ",
    "ẹ",
    "ê",
    "ế",
    "ề",
    "ể",
    "ễ",
    "ệ",
    "í",
    "ì",
    "ỉ",
    "ĩ",
    "ị",
    "ó",
    "ò",
    "ỏ",
    "õ",
    "ọ",
    "ô",
    "ố",
    "ồ",
    "ổ",
    "ỗ",
    "ộ",
    "ơ",
    "ớ",
    "ờ",
    "ở",
    "ỡ",
    "ợ",
    "ú",
    "ù",
    "ủ",
    "ũ",
    "ụ",
    "ư",
    "ứ",
    "ừ",
    "ử",
    "ữ",
    "ự",
    "ý",
    "ỳ",
    "ỷ",
    "ỹ",
    "ỵ",
  ];
  const plainLetters = [
    "a",
    "a",
    "a",
    "a",
    "a",
    "a",
    "a",
    "a",
    "a",
    "a",
    "a",
    "a",
    "a",
    "a",
    "a",
    "a",
    "a",
    "d",
    "e",
    "e",
    "e",
    "e",
    "e",
    "e",
    "e",
    "e",
    "e",
    "e",
    "e",
    "i",
    "i",
    "i",
    "i",
    "i",
    "o",
    "o",
    "o",
    "o",
    "o",
    "o",
    "o",
    "o",
    "o",
    "o",
    "o",
    "o",
    "o",
    "o",
    "o",
    "o",
    "o",
    "u",
    "u",
    "u",
    "u",
    "u",
    "u",
    "u",
    "u",
    "u",
    "u",
    "u",
    "y",
    "y",
    "y",
    "y",
    "y",
  ];
  for (let i = 0; i < accents.length; i++) {
    str = str.replace(new RegExp(accents[i], "g"), plainLetters[i]);
  }
  return str;
};

export const wordSearch = (search, word) => {
  const searchLower = search.toLowerCase();
  return (
    word.word.toLowerCase().includes(searchLower) ||
    removeVietnameseTones(word.word).toLowerCase().includes(searchLower) ||
    word.translation.toLowerCase().includes(searchLower) ||
    word.literalTranslation.toLowerCase().includes(searchLower) ||
    word.notes.toLowerCase().includes(searchLower)
  );
};
