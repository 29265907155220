const isValidJwt = (jwt) => {
  const payload = JSON.parse(atob(jwt.split(".")[1]));
  const now = Date.now() / 1000;
  if (payload.exp < now) {
    return false;
  }
  return true;
};

export const getJwt = async () => {
  const jwt = localStorage.getItem("jwt");
  if (!jwt) {
    return false;
  }
  if (!isValidJwt(jwt)) {
    const loginCode = localStorage.getItem("loginCode");
    const error = await login(loginCode);
    if (error) {
      return false;
    }
    return localStorage.getItem("jwt");
  }
  return jwt;
};

export const login = async (loginCode) => {
  try {
    const response = await fetch("/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ login_code: loginCode }),
    });

    const data = await response.json();

    if (data.success && data.token) {
      localStorage.setItem("jwt", data.token);
      localStorage.setItem("loginCode", loginCode);
      return data.token;
    } else {
      return false;
    }
  } catch (error) {
    console.error("There was an error logging in:", error);
    return false;
  }
};

export const logout = () => {
  localStorage.removeItem("jwt");
  localStorage.removeItem("loginCode");
};

export const fetchWords = async (jwt) => {
  try {
    const response = await fetch("/api/words", {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    const result = await response.json();

    if (result.error) {
      return [result.error, false];
    } else {
      return [
        false,
        result.results.sort((a, b) => a.word.localeCompare(b.word)),
      ];
    }
  } catch (err) {
    console.error(err);
    return ["An error occurred. Please try again later.", false];
  }
};

export const addWords = async (jwt, formData) => {
  try {
    const response = await fetch("/api/words-add", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        word: formData.word.trim(),
        translation: formData.translation.trim(),
        literal_translation: formData.literalTranslation.trim(),
        notes: formData.notes.trim(),
      }),
    });

    const result = await response.json();

    if (result.error) {
      return [result.error, false];
    } else {
      return [
        false,
        result.results.sort((a, b) => a.word.localeCompare(b.word)),
      ];
    }
  } catch (err) {
    console.error(err);
    return ["An error occurred. Please try again later.", false];
  }
};

export const addWordsBulk = async (jwt, text, type) => {
  try {
    const response = await fetch("/api/settings-import", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        text,
        type,
      }),
    });

    const result = await response.json();

    if (result.error) {
      return [result.error, false];
    }
  } catch (err) {
    console.error(err);
    return ["An error occurred. Please try again later.", false];
  }
};

export const editWords = async (jwt, formData) => {
  try {
    const response = await fetch("/api/words-edit", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: formData.id,
        word: formData.word.trim(),
        translation: formData.translation.trim(),
        literal_translation: formData.literalTranslation.trim(),
        notes: formData.notes.trim(),
      }),
    });

    const result = await response.json();

    if (result.error) {
      return [result.error, false];
    } else {
      return [
        false,
        result.results.sort((a, b) => a.word.localeCompare(b.word)),
      ];
    }
  } catch (err) {
    console.error(err);
    return ["An error occurred. Please try again later.", false];
  }
};

export const delWords = async (jwt, id) => {
  try {
    const response = await fetch("/api/words-delete", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    });

    const result = await response.json();

    if (result.error) {
      return [result.error, false];
    } else {
      return [
        false,
        result.results.sort((a, b) => a.word.localeCompare(b.word)),
      ];
    }
  } catch (err) {
    console.error(err);
    return ["An error occurred. Please try again later.", false];
  }
};

export const delWordsAll = async (jwt) => {
  try {
    const response = await fetch("/api/words-delete-all", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });

    const result = await response.json();

    if (result.error) {
      return [result.error, false];
    }
  } catch (err) {
    console.error(err);
    return ["An error occurred. Please try again later.", false];
  }
};

export const fetchLetters = async (jwt) => {
  try {
    const response = await fetch("/api/letters", {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    const result = await response.json();

    if (result.error) {
      return [result.error, false];
    } else {
      return [
        false,
        result.results.sort((a, b) => a.letter.localeCompare(b.letter)),
      ];
    }
  } catch (err) {
    console.error(err);
    return ["An error occurred. Please try again later.", false];
  }
};

export const addLetter = async (jwt, formData) => {
  try {
    const response = await fetch("/api/letters-add", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        letter: formData.letter.trim(),
        notes: formData.notes.trim(),
      }),
    });

    const result = await response.json();

    if (result.error) {
      return [result.error, false];
    } else {
      return [
        false,
        result.results.sort((a, b) => a.letter.localeCompare(b.letter)),
      ];
    }
  } catch (err) {
    console.error(err);
    return ["An error occurred. Please try again later.", false];
  }
};

export const addFullAlphabet = async (jwt) => {
  console.log("my jwt: ", jwt);
  try {
    const response = await fetch("/api/letters-populate", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    if (result.error) {
      return [result.error, false];
    } else {
      return [
        false,
        result.results.sort((a, b) => a.letter.localeCompare(b.letter)),
      ];
    }
  } catch (err) {
    console.error(err);
    return ["An error occurred. Please try again later.", false];
  }
};

export const editLetter = async (jwt, formData) => {
  try {
    const response = await fetch("/api/letters-edit", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: formData.id,
        notes: formData.notes.trim(),
      }),
    });

    const result = await response.json();

    if (result.error) {
      return [result.error, false];
    } else {
      return [
        false,
        result.results.sort((a, b) => a.letter.localeCompare(b.letter)),
      ];
    }
  } catch (err) {
    console.error(err);
    return ["An error occurred. Please try again later.", false];
  }
};

export const delLetter = async (jwt, id) => {
  try {
    const response = await fetch("/api/letters-delete", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    });

    const result = await response.json();

    if (result.error) {
      return [result.error, false];
    } else {
      return [
        false,
        result.results.sort((a, b) => a.letter.localeCompare(b.letter)),
      ];
    }
  } catch (err) {
    console.error(err);
    return ["An error occurred. Please try again later.", false];
  }
};
