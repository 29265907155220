import React from "react";

const LiteralTranslationParser = ({ paragraph }) => {
  const colors = [
    "p-1 bg-slate-100 text-rose-700",
    "p-1 bg-slate-200 text-yellow-500",
  ];

  return (
    <span>
      {paragraph.split(".").map((sentence, idx) => {
        if (!sentence.trim()) return null; // To avoid empty sentences or spaces

        // Assign a random color to each sentence
        const colorClass = colors[idx % colors.length];

        return (
          <span key={idx} className={colorClass}>
            {sentence.trim() /* Removing the period */}
          </span>
        );
      })}
    </span>
  );
};

export default LiteralTranslationParser;
