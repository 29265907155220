import React, { useState, useEffect, Fragment } from "react";

import { Dialog, Transition } from "@headlessui/react";

import {
  fetchLetters,
  addLetter,
  editLetter,
  delLetter,
  fetchWords,
  addFullAlphabet,
} from "../api";

function Letter({ jwt }) {
  const [formData, setFormData] = useState({
    letter: "",
    notes: "",
  });
  const [letters, setLetters] = useState([]);
  const [words, setWords] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFormVisible, setFormVisible] = useState(false);
  const [letterDetail, setLetterDetail] = useState(null);
  const [editLet, setEditLet] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [showAllletters, setShowAllletters] = useState(false);

  useEffect(() => {
    handleFetchWords();
    handleFetchLetters();
  }, []);

  const handleFetchLetters = async () => {
    const [error, results] = await fetchLetters(jwt);
    if (error) {
      setError(error);
    } else {
      setLetters(results);
      setIsLoading(false);
    }
  };

  const handleFetchWords = async () => {
    const [error, results] = await fetchWords(jwt);
    if (error) {
      setError(error);
    } else {
      setWords(results);
    }
  };

  const handleSubmit = async () => {
    const [error, results] = await addLetter(jwt, formData);
    if (error) {
      setError(error);
    } else {
      setLetters(results);
      setFormData({
        letter: "",
        notes: "",
      });
      setFormVisible(false);
    }
  };

  function SpeakWord(word) {
    let synth = window.speechSynthesis;
    let utterThis = new SpeechSynthesisUtterance(word);
    utterThis.lang = "vi-VN";
    synth.speak(utterThis);
  }

  const handleDelete = async (id) => {
    const [error, results] = await delLetter(jwt, id);
    if (error) {
      setError(error);
    } else {
      setLetters(results);
    }
  };

  const handleEditSave = async () => {
    const [error, results] = await editLetter(jwt, {
      id: letterDetail.id,
      notes: editLet,
    });
    if (error) {
      setError(error);
    } else {
      setLetters(results);
      setEditLet("");
    }
  };

  const handleAddfull = async () => {
    const [error, results] = await addFullAlphabet(jwt);
    if (error) {
      setError(error);
    } else {
      setLetters(results);
    }
  };

  const letterExists =
    formData.letter &&
    letters.some(
      (l) => l.letter.toLowerCase() === formData.letter.toLowerCase(),
    );

  if (error) {
    return <div>{error}</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App px-4">
      <section className="max-w-4xl mx-auto mt-6">
        <h2 className="text-2xl mb-4 text-yellow-500 flex justify-between items-center">
          <span>Letters</span>
          <button
            onClick={() => setShowAllletters(!showAllletters)}
            type="button"
            className="rounded  text-yellow-500 px-2 py-1 text-sm shadow-sm ring-1 ring-inset ring-yellow-300  ml-auto"
          >
            {showAllletters ? "Show Only With Notes" : "Show All Letters"}
          </button>
          <button
            onClick={() => setFormVisible(!isFormVisible)}
            type="button"
            className="rounded  text-yellow-500 px-2 py-1 text-sm shadow-sm ring-1 ring-inset ring-yellow-300  ml-auto"
          >
            Add
          </button>
        </h2>
        {isFormVisible && (
          <section className="max-w-md mx-auto mt-6">
            <h2 className="text-xl mb-4 text-yellow-500">Add Letter</h2>
            <input
              className="border p-2 w-full mb-4"
              placeholder="Letter"
              value={formData.letter}
              onChange={(e) =>
                setFormData({ ...formData, letter: e.target.value })
              }
            />
            <textarea
              className="border p-2 w-full mb-4"
              placeholder="Notes"
              value={formData.notes}
              onChange={(e) =>
                setFormData({ ...formData, notes: e.target.value })
              }
            ></textarea>
            <button
              className={`bg-yellow-500 ${
                !formData.letter || letterExists
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-yellow-400"
              } text-white font-bold py-2 px-4 w-full`}
              onClick={handleSubmit}
              disabled={!formData.letter || letterExists}
            >
              {letterExists ? "Letter Exists" : "Add"}
            </button>
          </section>
        )}
        <ul className="divide-y divide-gray-200">
          {letters.map((letter) => {
            const shouldShowLetter =
              showAllletters || letter.notes.trim().length > 0;
            return shouldShowLetter ? (
              <li
                key={letter.id}
                className="flex items-center"
                onClick={() => {
                  setOpenModal(true);
                  setLetterDetail(letter);
                }}
              >
                <div className="flex w-full items-center">
                  <div className="flex-none text-4xl mb-4 md:mb-0 py-4">
                    {letter.letter.toLowerCase()}
                  </div>

                  <div className="flex-1 text-right truncate text-gray-500">
                    {letter.notes}
                  </div>
                </div>
              </li>
            ) : null;
          })}
        </ul>
      </section>
      {letterDetail && (
        <Transition.Root show={openModal} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 overflow-y-auto"
            onClose={setOpenModal}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* Main Modal Container */}
            <div className="fixed inset-0 z-10 p-6 flex items-center justify-center">
              {/* Modal Content */}
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform bg-white rounded-lg overflow-hidden shadow-xl transition-all">
                  <div className="p-4">
                    <ul className="my-2 md:mt-0 md:ml-4">
                      {words
                        .filter((w) =>
                          w.word
                            .toLowerCase()
                            .includes(letterDetail.letter.toLowerCase()),
                        )
                        .map((word) => (
                          <li key={word.id} className="py-4">
                            <button
                              onClick={() => SpeakWord(word.word)}
                              className="text-slate-500 hover:text-slate-700 "
                            >
                              {word.word}
                            </button>
                          </li>
                        ))}
                    </ul>

                    <textarea
                      rows={2}
                      name="comment"
                      id="comment"
                      className="my-2 block w-full bg-slate-100 rounded-md border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400  sm:text-sm sm:leading-6"
                      placeholder="Notes"
                      value={editLet || letterDetail.notes}
                      onChange={(e) => setEditLet(e.target.value)}
                    />

                    {editLet && editLet !== letterDetail.notes && (
                      <button
                        className="bg-yellow-500 hover:bg-yellow-400 text-white font-bold py-2 px-4 w-full"
                        onClick={handleEditSave}
                      >
                        Update
                      </button>
                    )}

                    <button
                      onClick={() => handleDelete(letterDetail.id)}
                      className="mt-4 text-red-300 hover:text-red-700"
                    >
                      Delete
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </div>
  );
}

export default Letter;
