import React, { useState, useEffect } from "react";
import {
  MagnifyingGlassIcon,
  SpeakerWaveIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";

import { fetchWords, addWords, delWords, editWords } from "../api";
import { wordSearch } from "../utils";
import LiteralTranslation from "../components/LiteralTranslationParser";

function Words({ jwt }) {
  const [formData, setFormData] = useState({
    word: "",
    translation: "",
    literalTranslation: "",
    notes: "",
  });
  const [words, setWords] = useState([]);
  const [visibleWordInfo, setVisibleWordInfo] = useState(null);
  const [isFormVisible, setFormVisible] = useState(false);
  const [editWord, setEditWord] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");

  useEffect(() => {
    handleFetchWords();
  }, [jwt]);

  const handleFetchWords = async () => {
    if (!jwt) return;
    const [error, results] = await fetchWords(jwt);
    if (!error) {
      setWords(results);
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    const [error, results] = await addWords(jwt, formData);
    if (error) {
      setError(error);
    } else {
      setWords(results);
      setFormData({
        word: "",
        translation: "",
        literalTranslation: "",
        notes: "",
      });
      setFormVisible(false);
    }
  };

  const handleEditSave = async () => {
    const [error, results] = await editWords(jwt, editWord);
    if (error) {
      setError(error);
    } else {
      setWords(results);
      setEditWord(false);
    }
  };

  const handleDelete = async (id) => {
    const [error, results] = await delWords(jwt, id);
    if (error) {
      setError(error);
    } else {
      setWords(results);
    }
  };

  function SpeakWord(word) {
    let synth = window.speechSynthesis;
    let utterThis = new SpeechSynthesisUtterance(word);
    utterThis.lang = "vi-VN";
    synth.speak(utterThis);
  }

  const wordExists =
    formData.word &&
    words.some((w) => w.word.toLowerCase() === formData.word.toLowerCase());

  if (error) {
    return <div>{error}</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App px-4">
      <section className="max-w-4xl mx-auto mt-6 ">
        <h2 className="text-2xl mb-4 text-yellow-500 flex justify-between items-center">
          <span>Words</span>
          <div className="flex flex-1 justify-center px-2 lg:ml-6 lg:justify-end">
            <div className="w-full max-w-lg lg:max-w-xs">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  id="search"
                  name="search"
                  className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-300 placeholder:text-gray-400 focus:bg-slate-50 focus:text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  type="search"
                />
              </div>
            </div>
          </div>
          <button
            onClick={() => setFormVisible(!isFormVisible)}
            type="button"
            className="rounded  text-yellow-500 px-2 py-1 text-sm shadow-sm ring-1 ring-inset ring-yellow-300  ml-auto"
          >
            Add
          </button>
        </h2>

        {isFormVisible && (
          <section className="max-w-md mx-auto mt-6">
            <h2 className="text-xl mb-4 text-yellow-500">Add Word</h2>
            <input
              className="border p-2 w-full mb-4"
              placeholder="Word"
              value={formData.word}
              onChange={(e) =>
                setFormData({ ...formData, word: e.target.value })
              }
            />
            <input
              className="border p-2 w-full mb-4"
              placeholder="Translation"
              value={formData.translation}
              onChange={(e) =>
                setFormData({ ...formData, translation: e.target.value })
              }
            />
            <input
              className="border p-2 w-full mb-4"
              placeholder="Literal Translation"
              value={formData.literalTranslation}
              onChange={(e) =>
                setFormData({ ...formData, literalTranslation: e.target.value })
              }
            />
            <textarea
              className="border p-2 w-full mb-4"
              placeholder="Notes"
              value={formData.notes}
              onChange={(e) =>
                setFormData({ ...formData, notes: e.target.value })
              }
            ></textarea>
            <button
              className={`bg-yellow-500 ${
                !formData.word || wordExists
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-yellow-400"
              } text-white font-bold py-2 px-4 w-full`}
              onClick={handleSubmit}
              disabled={!formData.word || wordExists}
            >
              {wordExists ? "Word Exists" : "Add"}
            </button>
          </section>
        )}

        <ul className="divide-y divide-gray-200">
          {words
            .filter((word) => wordSearch(search, word))
            .map((word) => (
              <div className="relative" key={word.id}>
                <li className="flex items-center py-4 relative">
                  <div className="flex-none">
                    <button
                      onClick={() => SpeakWord(word.word)}
                      className="text-slate-500 hover:text-slate-700"
                    >
                      <SpeakerWaveIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    <span className="text-4xl px-4">{word.word}</span>
                  </div>
                  <div className="flex-grow flex justify-end ">
                    {false && (
                      <span className="text-slate-400 truncate w-1/3 max-w-xs text-right ">
                        {word.translation}
                      </span>
                    )}
                    <button
                      onClick={() => {
                        setVisibleWordInfo(
                          visibleWordInfo === word.id ? null : word.id,
                        );
                        setEditWord(false);
                      }}
                      className="ml-4 text-slate-500 hover:text-slate-700"
                    >
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </li>

                {visibleWordInfo === word.id && (
                  <div className="my-2 p-4 border rounded-md bg-slate-50 z-10 text-slate-500">
                    {word.literalTranslation && (
                      <p>
                        Literal Translation:{" "}
                        <LiteralTranslation
                          paragraph={word.literalTranslation}
                        />
                      </p>
                    )}
                    {word.translation && <p>Translation: {word.translation}</p>}
                    {word.notes && <p>Notes: {word.notes}</p>}
                    <div className="flex justify-between">
                      {!editWord && (
                        <button
                          onClick={() => setEditWord(word)}
                          className="mt-4 text-slate-700 hover:text-slate-900"
                        >
                          Edit
                        </button>
                      )}
                      {editWord && (
                        <button
                          onClick={() => setEditWord(false)}
                          className="mt-4 text-slate-700 hover:text-slate-900"
                        >
                          Cancel Editting
                        </button>
                      )}
                      <button
                        onClick={() => handleDelete(word.id)}
                        className="mt-4 text-red-300 hover:text-red-700"
                      >
                        Delete
                      </button>
                    </div>
                    {editWord && (
                      <section className="max-w-md mx-auto mt-6">
                        <h2 className="text-xl mb-4 text-yellow-500">Edit</h2>
                        <input
                          className="border p-2 w-full mb-4"
                          placeholder="Word"
                          value={editWord.word}
                          onChange={(e) =>
                            setEditWord({ ...editWord, word: e.target.value })
                          }
                        />
                        <input
                          className="border p-2 w-full mb-4"
                          placeholder="Translation"
                          value={editWord.translation}
                          onChange={(e) =>
                            setEditWord({
                              ...editWord,
                              translation: e.target.value,
                            })
                          }
                        />
                        <input
                          className="border p-2 w-full mb-4"
                          placeholder="Literal Translation"
                          value={editWord.literalTranslation}
                          onChange={(e) =>
                            setEditWord({
                              ...editWord,
                              literalTranslation: e.target.value,
                            })
                          }
                        />
                        <textarea
                          className="border p-2 w-full mb-4"
                          placeholder="Notes"
                          value={editWord.notes}
                          onChange={(e) =>
                            setEditWord({ ...editWord, notes: e.target.value })
                          }
                        ></textarea>
                        <button
                          className={`bg-yellow-500 hover:bg-yellow-400 text-white font-bold py-2 px-4 w-full`}
                          onClick={handleEditSave}
                        >
                          Save
                        </button>
                      </section>
                    )}
                  </div>
                )}
              </div>
            ))}
        </ul>
      </section>
    </div>
  );
}

export default Words;
