import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { getJwt, logout, login } from "./api";

import Header from "./components/header";
import Home from "./pages/home";
import CreateAccount from "./pages/create_account";
import Login from "./pages/login";
import Words from "./pages/words";
import Letters from "./pages/letters";
import Settings from "./pages/settings";

export default function App() {
  const [jwt, setJwt] = useState(false);
  useEffect(() => {
    const handleJwt = async () => {
      const result = await getJwt();
      if (result) {
        setJwt(result);
      }
    };
    handleJwt();
  }, []);

  const handleLogout = () => {
    logout();
    setJwt(false);
  };

  const handleLogin = async (loginCode) => {
    const jwt = await login(loginCode);
    if (jwt) {
      setJwt(jwt);
      return true;
    }

    return false;
  };

  const routeList = [
    {
      name: "Home",
      path: "/",
      component: Home,
      nav: true,
      authRequired: false,
    },
    {
      name: "Create Account",
      path: "/create-account",
      component: CreateAccount,
      nav: true,
      authRequired: false,
    },
    {
      name: "Login",
      path: "/login",
      component: Login,
      nav: true,
      authRequired: false,
      props: { handleLogin },
    },
    {
      name: "Words",
      path: "/words",
      component: Words,
      nav: true,
      authRequired: true,
      props: { jwt },
    },
    {
      name: "Letters",
      path: "/letters",
      component: Letters,
      nav: true,
      authRequired: true,
      props: { jwt },
    },
    {
      name: "Settings",
      path: "/settings",
      component: Settings,
      nav: true,
      authRequired: true,
      props: { jwt },
    },
  ];

  return (
    <Router>
      <div className="min-h-full flex flex-col ">
        <Header routeList={routeList} handleLogout={handleLogout} jwt={jwt} />
        <div>
          <Routes>
            {routeList.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<route.component {...route.props} />}
              />
            ))}
          </Routes>
        </div>
      </div>
    </Router>
  );
}
