import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function Login({ handleLogin }) {
  const [loginCode, setLoginCode] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const results = await handleLogin(loginCode);
      if (results) {
        navigate("/words");
      } else {
        setMessage("There was an error logging in");
      }
    } catch (error) {
      console.error("There was an error logging in:", error);
      setMessage("There was an error logging in.");
    }
  };

  return (
    <div className="App px-8">
      <section className="max-w-4xl mx-auto mt-6">
        <h1 className="text-2xl text-slate-600 font-semibold my-4">Login</h1>
        <p className="text-slate-500">Enter your login code below.</p>
        <form onSubmit={handleSubmit}>
          <input
            name="password"
            type="password"
            placeholder="Enter login code"
            value={loginCode}
            onChange={(e) => setLoginCode(e.target.value)}
            className="p-2 mt-4 border rounded"
          />
          <button
            type="submit"
            className="ml-2 px-4 py-2 bg-rose-800 text-white rounded"
          >
            Go!
          </button>
        </form>
        {message && <p className="mt-4">{message}</p>}
      </section>
    </div>
  );
}

export default Login;
