import React, { useState } from "react";

import { addWordsBulk, delWordsAll } from "../api";

function Settings({ jwt }) {
  const [importText, setImportText] = useState("");
  const [importType, setImportType] = useState(false);

  const handleSubmit = async () => {
    await addWordsBulk(jwt, importText, importType);
    setImportText("");
    setImportType(false);
  };

  const handleClearAllWords = async () => {
    await delWordsAll(jwt);
    setImportText("");
    setImportType(false);
  };

  return (
    <div className="App px-4">
      <section className="max-w-4xl mx-auto mt-6">
        <h2 className="text-2xl mb-4 text-yellow-500 flex justify-between items-center">
          <span>Settings</span>
        </h2>
        <div className="col-span-full">
          <label
            htmlFor="about"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Bulk Import
          </label>
          <div className="mt-2">
            <textarea
              id="about"
              name="about"
              rows={3}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              value={importText}
              onChange={(e) => setImportText(e.target.value)}
            />
          </div>
          <fieldset>
            <div className="mt-6 space-y-6">
              <div className="flex items-center gap-x-3">
                <input
                  id="word"
                  name="word"
                  type="radio"
                  checked={importType === "word"}
                  onChange={() => setImportType("word")}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <label
                  htmlFor="word"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Import each word individually
                </label>
              </div>
              <div className="flex items-center gap-x-3">
                <input
                  id="line"
                  name="line"
                  type="radio"
                  checked={importType === "line"}
                  onChange={() => setImportType("line")}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <label
                  htmlFor="line"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Import each line
                </label>
              </div>
            </div>
          </fieldset>
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="submit"
              onClick={() => handleSubmit()}
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Import
            </button>
          </div>
        </div>
        <div className="col-span-full border-t-2 mt-4 pt-4">
          <button
            type="submit"
            onClick={() => handleClearAllWords()}
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Clear All Words
          </button>
        </div>
      </section>
    </div>
  );
}

export default Settings;
