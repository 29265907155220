import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { getJwt } from "../api";

function Home() {
  const [checkedLogin, setCheckedLogin] = useState(false);
  useEffect(() => {
    const isLoggedIn = async () => {
      const jwt = await getJwt();
      if (jwt) {
        navigate("/words");
      }
      setCheckedLogin(true);
    };
    isLoggedIn();
  }, []);

  const navigate = useNavigate();

  return (
    <div className="App px-8">
      <section className="max-w-4xl mx-auto mt-6">
        <h1 className="text-2xl text-slate-600 font-semibold my-4">
          Welcome &#x1F60A;
        </h1>
        <p className="text-slate-500">
          This is a simple dictionary app to track Vietnamese characters and
          words.
        </p>
        {!checkedLogin && (
          <p className="text-slate-50">
            Checking logged in status... one moment please...
          </p>
        )}
      </section>
    </div>
  );
}

export default Home;
